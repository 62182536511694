import { createRouter, createWebHashHistory } from 'vue-router'
import { getUserInfo } from '@/request/api/user'
import store from '@/store/index.js'
import { filterAuthRouter, setAsyncRouters } from "@/utils/routeUtil.js";
import { getMenuData, chickPathInMenu } from "@/utils/commons.js";

export const baseRoutes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Home',
        // component: () => import('../views/Home.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
]

export const asyncRouters = [

  {
    // 代表Layout层
    path: '/',
    name: 'Layout',

    meta: {
      // filterAuthRouter 过滤路由时 权限暂时的修改方案，之后要修改
      auth: true,
      roles: ['999', '888'],
    },
    component: () => import('@/views/Index'),
    children: [
      {
        path: '/hotelData/index',
        name: 'hotelData',
        meta: {
          title: '酒店数据',
          icon: 'hotel_data',
          auth: true,
          isSelected: false,
          roles: ['888'],
        },
        component: () => import('@/views/hotelData/index')
      },
      {
        path: '/hotelManagement/index',
        name: 'hotelManagement',
        meta: {
          title: '酒店管理',
          icon: 'hotel_management',
          auth: true,
          isSelected: false,
          roles: ['888'],
        },
        component: () => import('@/views/hotelManagement/index')
      },
      {
        path: '/adminManagement/index',
        name: 'adminManagement',
        meta: {
          title: '管理员管理',
          icon: 'admin_management',
          auth: true,
          isSelected: false,
          roles: ['888'],
        },
        component: () => import('@/views/adminManagement/index')
      },
      {
        path: '/withdrawCashManagement/index',
        name: 'withdrawCashManagement',
        meta: {
          title: '提现管理',
          icon: 'withdraw_management',
          auth: true,
          isSelected: false,
          roles: ['888'],
        },
        component: () => import('@/views/withdrawCashManagement/index')
      },

      // 这里往上是总后台的路由
      {
        path: '/orderManagement',
        name: 'orderManagement',
        meta: {
          title: '酒店订单管理',
          icon: 'ze-orders',
          auth: true,
          isSelected: false,
          roles: ['999'],
        },
        children: [
          {
            path: '/orderManagement/mallOrders',
            name: 'mallOrders',
            meta: {
              title: '迷你商城订单',
              icon: 'mall',
              id: 1,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/mallOrders')
          },
          {
            path: '/orderManagement/leaseOrder',
            name: 'leaseOrder',
            meta: {
              title: '加借物品订单',
              icon: 'security',
              id: 2,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/leaseOrder')
          },
          {
            path: '/orderManagement/fooddeliveryOrder',
            name: 'fooddeliveryOrder',
            meta: {
              title: '房间送餐订单',
              icon: 'food',
              id: 3,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/fooddeliveryOrder')
          },
          {
            path: '/orderManagement/laundryOrder',
            name: 'laundryOrder',
            meta: {
              title: '洗衣订单',
              icon: 'laundry',
              id: 5,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/laundryOrder')
          },
          {
            path: '/orderManagement/cleaningOrder',
            name: 'cleaningOrder',
            meta: {
              title: '房间打扫订单',
              icon: 'brush',
              id: 50,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/cleaningOrder')
          },
          {
            path: '/orderManagement/repairOrder',
            name: 'repairOrder',
            meta: {
              title: '房间报修订单',
              icon: 'tools',
              id: 100,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/repairOrder')
          },
          {
            path: '/orderManagement/invoiceOrder',
            name: 'invoiceOrder',
            meta: {
              title: '预约开票订单',
              icon: 'invoice',
              id: 150,
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/invoiceOrder')
          },
          {
            path: '/orderManagement/evaluate',
            name: 'evaluate',
            meta: {
              title: '评论吐槽',
              icon: 'evaluate',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/orderManagement/evaluate')
          },
        ]
      },
      {
        path: '/basicSettings',
        name: 'basicSettings',
        meta: {
          title: '酒店基础信息',
          icon: 'user-settings',
          auth: true,
          isSelected: false,
          roles: ['999'],
        },
        children: [
          {
            path: '/basicSettings/indexManagement',
            name: 'indexManagement',
            meta: {
              title: '首页管理',
              icon: 'home',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/indexManagement')
          },
          {
            path: '/basicSettings/roomQRcode',
            name: 'roomQRcode',
            meta: {
              title: '客房管理',
              icon: 'qr_code',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/roomQRcode')
          },
          {
            path: '/basicSettings/staffInfo',
            name: 'staffInfo',
            meta: {
              title: '员工信息',
              icon: 'peoples',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/staffInfo')
          },
          {
            path: '/basicSettings/bannerManagement',
            name: 'bannerManagement',
            meta: {
              title: 'banner管理',
              icon: 'picture',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/bannerManagement')
          },
          {
            path: '/basicSettings/serviceGuide',
            name: 'serviceGuide',
            meta: {
              title: '服务指南管理',
              icon: 'serve',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/serviceGuide')
          },
          {
            path: '/basicSettings/applyForWithdrawal',
            name: 'applyForWithdrawal',
            meta: {
              title: '申请提现',
              icon: 'withdraw_management',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/basicSettings/applyForWithdrawal')
          }

        ]
      },
      {
        path: '/serviceConfiguration',
        name: 'serviceConfiguration',
        meta: {
          title: '酒店服务配置',
          icon: 'function',
          auth: true,
          isSelected: false,
          roles: ['999'],
        },
        children: [
          {
            path: '/serviceConfiguration/mallConfiguration',
            name: 'mallConfiguration',
            meta: {
              title: '迷你商城配置',
              icon: 'mall',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/serviceConfiguration/mallConfiguration')
          },
          {
            path: '/serviceConfiguration/fooddeliveryConfiguration',
            name: 'fooddeliveryConfiguration',
            meta: {
              title: '客房送餐配置',
              icon: 'food',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/serviceConfiguration/fooddeliveryConfiguration')
          },
          {
            path: '/serviceConfiguration/laundryConfiguration',
            name: 'laundryConfiguration',
            meta: {
              title: '洗衣配置',
              icon: 'laundry',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/serviceConfiguration/laundryConfiguration')
          },
          {
            path: '/serviceConfiguration/leaseConfiguration',
            name: 'leaseConfiguration',
            meta: {
              title: '加借物品配置',
              icon: 'security',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/serviceConfiguration/leaseConfiguration')
          },
          /* {
            path: '/serviceConfiguration/couponConfiguration',
            name: 'couponConfiguration',
            meta: {
              title: '优惠券配置',
              icon: 'ticket',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/serviceConfiguration/couponConfiguration')
          } */
        ]
      },
      {
        path: '/eCommerceConfiguration',
        name: 'eCommerceConfiguration',
        meta: {
          title: '电商管理配置',
          icon: 'e-commerce',
          auth: true,
          isSelected: false,
          roles: ['888','999'],
        },
        children: [
          /* {
            path: '/eCommerceConfiguration/meituanMall',
            name: 'meituanMall',
            meta: {
              title: '美团选品配置',
              icon: 'mall',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/eCommerceConfiguration/meituanMall')
          }, */
          {
            path: '/eCommerceConfiguration/jingdongMall',
            name: 'jingdongMall',
            meta: {
              title: '京东选品配置',
              icon: 'jingdong',
              auth: true,
              isSelected: false,
              roles: ['888','999'],
            },
            component: () => import('@/views/eCommerceConfiguration/jingdongMall')
          },
          /* {
            path: '/Test/test4',
            name: 'test4',
            meta: {
              title: '测试页面',
              icon: 'meituan',
              auth: true,
              isSelected: false,
              roles: ['999'],
            },
            component: () => import('@/views/Test/Test4')
          }, */
        ]
      }
    ]
  }
]

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/Index.vue'),
    redirect: '/redirect',

  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/Index.vue'),
    redirect: '/redirect',
    children: [
      {
        path: '/home',
        name: 'Home',
        //component: () => import('../views/Home.vue')
      }
    ]
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),

  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    // 去登录页
    next()
  } else {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let token = localStorage.getItem('token')
    if (userInfo != null) {
      // 有用户信息
      let loginStatus = store.state.user.loginStatus
      if (loginStatus !== 'online') {
        try {
          // 没报错就是接口通的
          let data = await getUserInfo()

          store.commit('setLoginStatus', 'online')
          store.commit('setUserInfo', userInfo)
          store.commit('setToken', token)
          // 根据用户消息里的角色，生成路由
          // userInfo.authorityId 为管理员权限 888超级管理员  999酒店管理员
          // 用根据权限过滤出来后的路由来添加动态路由

          let filterRouters = filterAuthRouter(asyncRouters, userInfo.authorityId)

          //console.log('过滤后的路由', filterRouters);
          setAsyncRouters(filterRouters)
          // 同时设置一级菜单数据
          store.commit('setMenuData', filterRouters[0].children)
          //console.log(router.getRoutes());
          next(to.path);
        } catch (error) {
          // 请求要是不通接口会直接返回401错误，用catch捕获
          next(`/login`);
        }
      } else {
        // 登录状态未失效，直接放行
        // 跳转的地址如果在二级菜单里，则设置一下菜单的数据
        if (chickPathInMenu(to.path)) {
          getMenuData(to.path)
        }
        next();
      }
    } else {
      // 没登陆的全部重定向到登录页
      next(`/login`)
    }
  }
})

export default router
