import axios from "axios";
import router from "@/router";
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store/index.js'


const axiosInstance = axios.create({
  // VUE_APP_BASE_API = '/backenapi'
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true, // 设置跨域
  timeout: 60000 * 2 // 设置10秒超时时间
});

// 请求拦截
axiosInstance.interceptors.request.use(request => {
  // 是否需要设置 token
  const isToken = request.headers.isToken
  if (isToken !== false) {
    request.headers['x-token'] = localStorage.getItem('token')
  }
  return request;
}, error => {
  return error;
});

// 响应拦截
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /* 网络连接失败自动处理 */
    if (error.message.indexOf("Network Error") !== -1) {
      ElMessage.error("无法连接服务器!");
      return Promise.reject(error);
    }
    /* 网络请求超时自动处理 */
    if (error.message.indexOf("timeout") !== -1) {
      ElMessage.error("服务器请求超时!");
      return Promise.reject(error);
    }
    /* 请求400处理 */
    if (error.response.status === 400) {
      ElMessage.error("请求不合法!");
      return Promise.reject(error);
    }
    if (error.response.status === 401) {

      // loginStatus为空或者offline时，一定为重新进入页面
      let loginStatus = store.state.user.loginStatus
      if (loginStatus == 'online') {
        // 登录状态为在线，此时触发401状态码，说明在业务逻辑页面内请求接口不通，说明在页面闲置太久了，后台登录状态掉线了，需要弹窗让用户确认跳转登录页重新登录
        store.commit('setLoginStatus', 'offline')
        ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
        ).then(() => {
          router.replace({ path: '/login' })
        }).catch(() => {

        });
      } else {
        // 登录状态为离线，此时触发401状态码，说明用户肯定是刷新了页面，或者重新进入页面了，loginStatus为离线状态触发的第一个请求只能是在路由跳转守卫里的接口：获取用户信息来验证登录状态，然而在这里都401了，则说明用户根本就没机会进到业务逻辑页面，直接跳转登录页，并且给个弹窗提示，不需要确认的那种
        ElMessage.error("无效的会话，或者会话已过期，请重新登录。");

      }
      return Promise.reject(error);
    }
    /* 请求404处理 */
    if (error.response.status === 404) {
      ElMessage.error("请求接口不存在!");
      return Promise.reject(error);
    }
    if (error.response.status === 415) {
      ElMessage.error("请求415!");
      return Promise.reject(error);
    }
    // 响应500处理
    if (error.response.status === 500) {
      ElMessage.error("服务器内部错误!");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });

export default axiosInstance;
